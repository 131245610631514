<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <habit-logo />
      <h2 class="brand-text text-primary ml-1">
        Habit
      </h2>
    </b-link>

    <!-- Loading -->
    <loading
      v-if="isLoading || isSubmitting"
      :active="true" 
      :is-full-page="true"
      :color="colors.primary"
    />

    <div v-if="!isLoading" class="misc-inner p-2 p-sm-3 mt-5">
      <div class="w-100 text-center">
        <h2 v-if="locationNotFound" class="text-danger">
          {{ $t('location_not_found_check_url') }}
        </h2>
      
        <div v-else>
          <h5 v-if="!isSubmitted" class="mb-2 mb-sm-5">
            <u>{{ $t('label.Location') }}</u>: <b>{{ location }}</b>
          </h5>

          <h2 class="mb-sm-3">
            {{ isSubmitted ? $t('thank_you_submitting_response') : $t('how_are_you_feeling_today') }}
          </h2>

          <div v-if="isSubmitted">
            <!-- State -->
            <b-row>
              <b-col cols="12">
                <img v-if="useBatteryIcons" :src="batteryIcons[state]" :width="iconSize" :height="iconSize" alt="Battery" class="my-3 my-sm-2" />
                <feather-icon v-else :icon="iconNames[state]" :size="iconSize" :class="`my-3 my-sm-2 text-${variants[state]}`" />
              </b-col>
            </b-row>
  
            <!-- Comment -->
            <b-row v-if="comment" class="mt-1">
              <b-col cols="12">
                <b-form-textarea
                  v-model="comment"
                  rows="3"
                  :disabled="true"
                />
              </b-col>
            </b-row>
          </div>

          <div v-else>
            <b-row>
              <!-- Happy -->
              <b-col sm="4">
                <b-button class="mx-2 my-1" :variant="`outline-${useBatteryIcons ? 'secondary' : 'success'}`" :pressed="state === 'happy'" @click="state = 'happy'">
                  <img v-if="useBatteryIcons" :src="batteryIcons.happy" :width="iconSize" :height="iconSize" alt="Battery 1" />
                  <feather-icon v-else :icon="iconNames.happy" :size="iconSize" class="text-success" />
                </b-button>
              </b-col>
              <!-- Neutral -->
              <b-col sm="4">
                <b-button class="mx-2 my-sm-1" :variant="`outline-${useBatteryIcons ? 'secondary' : 'warning'}`" :pressed="state === 'neutral'" @click="state = 'neutral'">
                  <img v-if="useBatteryIcons" :src="batteryIcons.neutral" :width="iconSize" :height="iconSize" alt="Battery 2" />
                  <feather-icon v-else :icon="iconNames.neutral" :size="iconSize" class="text-warning" />
                </b-button>
              </b-col>
              <!-- Sad -->
              <b-col sm="4">
                <b-button class="mx-2 my-1" :variant="`outline-${useBatteryIcons ? 'secondary' : 'danger'}`" :pressed="state === 'sad'" @click="state = 'sad'">
                  <img v-if="useBatteryIcons" :src="batteryIcons.sad" :width="iconSize" :height="iconSize" alt="Battery 2" />
                  <feather-icon v-else :icon="iconNames.sad" :size="iconSize" class="text-danger" />
                </b-button>
              </b-col>
            </b-row>

            <!-- Comment -->
            <b-row class="mt-1">
              <b-col cols="12">
                <b-form-group :label="$t('message.feel_free_to_share_comment')">
                  <b-form-textarea
                    v-model="comment"
                    rows="3"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <b-button
            v-if="!isSubmitted"
            variant="primary"
            class="mt-1 mt-sm-3 btn-lg"
            :disabled="!state || isSubmitting"
            @click="submit"
          >
            {{ $t('message.submit') }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink, BButton, BFormTextarea, BFormGroup } from 'bootstrap-vue'
import HabitLogo from '@core/layouts/components/Logo.vue'
import { ref, onMounted } from "@vue/composition-api"
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import useNotifications from "@/composables/useNotifications"
import { useRouter } from '@core/utils/utils'
import i18n from '@/libs/i18n'
import useJwt from '@/auth/jwt/useJwt'
import { colors } from '@/constants'
import realmConnection from '@/views/habit/realm'
import Battery1 from '@/assets/images/svg/Battery1.svg'
import Battery2 from '@/assets/images/svg/Battery2.svg'
import Battery3 from '@/assets/images/svg/Battery3.svg'

export default {
  components: {
    HabitLogo,
    BLink,
    BButton,
    Loading,
    BFormTextarea,
    BFormGroup,
  },
  setup() {
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const { route } = useRouter()
    const { getItem, createItem, ObjectId } = realmConnection()
    const state = ref("")
    const location = ref("")
    const locationNotFound = ref(false)
    const isLoading = ref(true)
    const isSubmitting = ref(false)
    const isSubmitted = ref(false)
    const iconNames = { happy: 'SmileIcon', neutral: 'MehIcon', sad: 'FrownIcon' }
    const variants = { happy: 'success', neutral: 'secondary', sad: 'danger' }
    const batteryIcons = { happy: Battery1, neutral: Battery2, sad: Battery3 }
    const widthBreakpoint = 576
    const iconSize = ref("120")
    const comment = ref("")
    const query = { _id: ObjectId(route.value.params.locationId) }
    const useBatteryIcons = ref(false)

    onMounted(async () => {
      // Define locale based on the language of the browser UI
      if (window.navigator.language?.startsWith('es')) i18n.locale = 'es'

      // Adjust icon size depending on viewport width
      if (window.innerWidth < widthBreakpoint) iconSize.value = "100"

      try {
        // If user is not logged in (either as a real or anonymous user), then log in as an anonymous user
        if (!useJwt.realm.currentUser) await useJwt.loginAsAnonymousUser()

        // Get location name based on location id from URL param
        const item = await getItem({ collection: 'location', query })
        if (!item) throw new Error('Item not found')

        useBatteryIcons.value = item.batteryIcons
        location.value = item.location
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.location_fetch_error'))
        locationNotFound.value = true
        
      } finally {
        isLoading.value = false
      }
    })

    const submit = async () => {
      isSubmitting.value = true

      const payload = {
        location: ObjectId(route.value.params.locationId),
        createdAt: new Date(),
        answer: state.value,
        read: false,
        comment: comment.value
      }

      try {
        await createItem({ collection: 'employee_survey', payload })
        showSuccessMessage(i18n.t('message.answer_success'))
        isSubmitted.value = true
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.answer_submit_error'))
      } finally {
        isSubmitting.value = false
      }
    }

    return {
      state,
      location,
      locationNotFound,
      submit,
      isLoading,
      isSubmitting,
      isSubmitted,
      iconNames,
      variants,
      iconSize,
      comment,
      colors,
      useBatteryIcons,
      batteryIcons
    };
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>